import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'

//const beforeUrl = "http://muzu.jiasc.com/index/";
const beforeUrl = "https://htgl.xiaoliy.com/index/";
//请求拦截器
axios.interceptors.request.use(config=>{
  	//设置跨域头部,虽然很多浏览器默认都是使用json传数据，但咱要考虑IE浏览器。
    config.headers = {
        // 'Content-Type':'application/json'
        'Content-Type':'application/x-www-form-urlencoded'
    };
  	// 拼接URL
    if(config.is_config==undefined){
        config.url = beforeUrl + config.url;
    }
    // 因后端代码 PHP 接收参数时，用的是接收 FormData（表单数据）格式的方法，故需把参数转换成FormData格式
    config.transformRequest = [function (data) {
        if (data instanceof FormData) {
            if (!data.get("user_id")) {
                data.append('user_id', store.state.userInfo.admin_id);
                
            }
            data.append('access_token', store.state.access_token);
            //所有请求带token，后台验证
            data.append('token', store.state.token);
            return data;
        } else {
            let req = '';
            let keyarr = [];
            for (let item in data) {
                keyarr.push(item);//存储键
            }
            if(store.state.userInfo){
            	//追加用户ID
	            if (keyarr.indexOf("user_id")==-1) {
	                data['user_id'] = store.state.userInfo.admin_id;
	            }
	            data['access_token'] = store.state.access_token;
            }
            //所有请求带token，后台验证
            data['token'] = store.state.token;
            // data['timestamp'] = timestamp;
            for (let i in data) {
                req += encodeURIComponent(i) + "=" +encodeURIComponent(data[i]) + "&";
            }
            req = req?req.substr(0, req.length - 1):""; //去最后&
//         	console.log(req)
            return req;
        }
    }];
    //console.log('请求之前经过这里',config);
    return config;
},err=>{
  	// console.log('请求失败前经过这里',err);
    return;
})

//响应拦截
axios.interceptors.response.use(
    response => {
        // console.log('返回正确且事先经过这里');
        if (!response.data.status&&response.data.errcode>100) {
            store.state.access_token = '';
            router.push({path: '/login'});
        }
        return response;
    },
    error => {
        // console.log('返回错误也事先经过这里');
        return Promise.reject(error);;
    }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url,params={}){
    return new Promise((resolve,reject) => {
        axios.get(url,{
            params:params
        })
        .then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

 export function post(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.post(url,data).then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
    })
 }

 /**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.patch(url,data)
        .then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
    })
}

 /**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.put(url,data)
        .then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
    })
}
