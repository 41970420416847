import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import BaiduMap from 'vue-baidu-map'
import VueQuillEditor from 'vue-quill-editor'
import * as echarts from 'echarts'
Vue.prototype.$echarts=echarts
//定义全局变量
import {post,fetch,patch,put} from './api/request'
Vue.prototype.fd_post=post;

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/font/iconfont.css'
//获取缓存数据
store.getters.getStorage;

Vue.use(Element)
Vue.use(VueQuillEditor)
Vue.use(BaiduMap,{ak:'9VlqowEYnvNvTbwzDotAZ25TWApLQE3b&s=1'})
Vue.config.productionTip = false

Vue.filter('digit',function(num){
  const str=(parseFloat(num).toLocaleString("zh-CN", {style:"currency", currency:"CNY"}))
  return str
})

Vue.filter('digits',function(num){
  const str=num.toString().split('').reverse()
  for(let i =0;i<str.length;i++){
    if(i%3==0&&i!==0){
      str[i]=str[i]+','
    }
  }
  const result=str.reverse().join('')
  return result
})

Vue.filter('numFormat',function(num){
  if(num >= 10000) {
    num = Math.round(num/1000)/10 + 'W';
  } else if (num >= 1000) {
    num = Math.round(num/100)/10 + 'K';
  }
  return num;
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
